<template>
  <div class="page" id="usageDetailed">
    <h1 class="my-2">{{ $t('usage.detailed.title') }}</h1>

    <div class="mb-2 px-4 py-3 card">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="d-flex flex-column align-items-start">
            <label class="m-0">{{
              $t('usage.detailed.filters.period.label')
            }}</label>
            <div class="d-flex gap-2 w-100">
              <DatePicker
                class="flex-fill"
                v-model="filters.period.from"
                :config="{
                  maxDate: 'today',
                  dateFormat: 'Y-m-d',
                  altFormat: 'F j, Y'
                }"
              />
              <DatePicker
                class="flex-fill"
                v-model="filters.period.to"
                :config="{
                  maxDate: 'today',
                  dateFormat: 'Y-m-d',
                  altFormat: 'F j, Y'
                }"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex flex-column align-items-start">
            <label class="m-0">{{
              $t('usage.detailed.filters.service-name.label')
            }}</label>
            <b-input class="border w-100" v-model="filters.serviceName" />
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="d-flex flex-column align-items-start">
            <label class="m-0">{{
              $t('usage.detailed.filters.account.label')
            }}</label>
            <Select
              class="w-100"
              :preselectFirst="true"
              :options="accounts"
              v-model="filters.account"
            >
            </Select>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex flex-column align-items-start">
            <label class="m-0">{{
              $t('usage.detailed.filters.package.label')
            }}</label>
            <Select
              class="w-100"
              :preselectFirst="true"
              :options="packages"
              v-model="filters.package"
            >
            </Select>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="d-flex flex-column align-items-start">
            <label class="m-0">{{
              $t('usage.detailed.filters.origin.label')
            }}</label>
            <Select
              class="w-100"
              :preselectFirst="true"
              :options="origins"
              v-model="filters.origin"
            >
            </Select>
          </div>
        </div>
        <div class="d-flex align-items-center gap-2 mt-4 col-12 col-lg-6">
          <b-button
            class="px-4"
            size="sm"
            @click="fetchUsageDetailed(1)"
            variant="primary"
          >
            {{ $t('usage.detailed.filters.search') }}
          </b-button>
          <b-button
            size="sm"
            class="px-4 d-flex align-items-center gap-2"
            variant="secondary"
            @click="downloadUsageDetailed()"
            :disabled="downloadLoading"
          >
            <half-circle-spinner
              v-show="downloadLoading"
              :size="20"
              color="#008ecf"
              class=""
            />
            {{ $t('usage.detailed.filters.download') }}
          </b-button>
        </div>
      </div>
    </div>

    <half-circle-spinner
      v-show="isLoading"
      :size="50"
      color="#008ecf"
      class="pre-loader"
    />

    <DataTable
      :fields="fields"
      :data="data"
      :pagination="pagination"
      v-if="!isLoading"
      @changePage="pageChange"
    >
      <template v-slot:origin="{ row }">
        <span :class="getOriginClass(row.origin)">
          {{ $t(`usage.detailed.table.columns.origins.${row.origin}`) }}
        </span>
      </template>
      <template v-slot:dt="{ row }">
        <span>
          {{
            moment(row.dt)
              .locale(locale)
              .format('DD-MM-YYYY, h:mm A')
          }}
        </span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import Vue from 'vue'
import DataTable from '../../../elements/Table.vue'
import UsageService from '../../../services/usage.service'
import DatePicker from '../../../elements/DatePicker.vue'
import moment from 'moment'
import Select from '../../../elements/Select.vue'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    HalfCircleSpinner,
    DataTable,
    DatePicker
  },
  data() {
    return {
      fields: [],
      data: [],
      pagination: {
        page: 1,
        perPage: 10,
        totalPages: 1
      },
      isLoading: false,
      downloadLoading: false,
      filters: {
        period: {
          from: moment().format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD')
        },
        serviceName: '',
        account: '',
        package: '',
        origin: ''
      },
      packages: [],
      accounts: [],
      origins: [],
      moment
    }
  },
  methods: {
    getOriginClass(origin) {
      if (origin === 'Incoming') return 'badge bg-primary'
      if (origin === 'Outgoing') return 'badge bg-success'
    },
    pageChange(page) {
      this.pagination.page = page
      this.fetchUsageDetailed(page)
    },
    async fetchUsageDetailed(page = 1) {
      this.pagination.page = page
      this.isLoading = true

      try {
        const filters = { ...this.filters }
        const difference = moment(filters.period.from).diff(
          moment(filters.period.to),
          'days'
        )
        if (Math.abs(difference) > 30) {
          this.toast(
            this.$t('usage.detailed.filters.period.errors.invalid', {
              days: 30
            }),
            {
              type: 'error'
            }
          )
          this.isLoading = false
          return
        }

        const res = await UsageService.fetchUsageDetailed(filters, page)
        this.data = res.list
        this.fields = res.columns.map(column => ({
          accessor: column,
          header: this.$t(`usage.detailed.table.columns.${column}`)
        }))
        this.pagination.totalPages = res.hasNext ? page + 1 : page
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async downloadUsageDetailed() {
      try {
        this.downloadLoading = true
        const filters = { ...this.filters }
        const res = await UsageService.downloadUsageDetailed(filters)
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `usage-detailed-${filters.period.from}-to-${filters.period.to ||
            filters.period.from}.csv`
        )
        document.body.appendChild(link)
        link.click()

        // cleanup
        link.remove()
      } catch (error) {
        console.error(error)
      } finally {
        this.downloadLoading = false
      }
    },
    async fetchFiltersOptions() {
      try {
        const {
          packages,
          accounts,
          origins
        } = await UsageService.fetchFiltersOptions()

        console.log(accounts)

        this.packages = packages
        this.accounts = accounts
        this.origins = origins.map(origin => ({
          ...origin,
          name: this.$t(`usage.detailed.filters.origin.options.${origin.name}`)
        }))
      } catch (error) {
        console.error(error)
      }
    }
  },
  computed: {
    locale() {
      return Vue.i18n.locale()
    }
  },
  mounted() {
    this.fetchUsageDetailed()
    this.fetchFiltersOptions()
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 0.5rem 1rem;

  &.active {
    background: #008ecf;
    color: white;
  }
}
.gap-2 {
  gap: 0.5rem;
}
</style>
